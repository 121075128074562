import React, { useState, useEffect } from "react";
 
import Navbar from "./Navbar";
import Faq from "./Faq.js";
import AnimatedSection from "./AnimatedSection";
import { FaTwitter, FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';


import "./../assets/home/style-new.css"
import down from "./../assets/images/common/down.svg"

import homeBanner from "../assets/home/home-banner.png";
import VideoPlayer from "./VideoPlayer.js";
import solidProof from "../assets/home/audit.png";
import bg1 from "../assets/home/bg-1.png";
import bg2 from "./../assets/home/bg-2.png";
import bgsky from "./../assets/home/bg_homesky.png";

import mafia1 from "./../assets/home/mafia-tr.png";
import uniswaphero from "./../assets/home/uniswaphero.svg";
import awshero from "./../assets/home/awshero.svg";
import metamaskhero from "./../assets/home/metamaskhero.svg";
import openAi from "./../assets/home/openAi.svg";
import trustwallethero from "./../assets/home/trustwallethero.svg";

import ambcrypto from "./../assets/home/about-ambcrypto.svg";
import coingape from "./../assets/home/about-coingape.svg";
import cointelegraph from "./../assets/home/about-cointelegraph.svg";
import yahoo from "./../assets/home/about-yahoo.svg";

import aboutUsImg from "./../assets/home/abou-us.png";
import chart from "../assets/home/chart.svg";
import howtobuy from "../assets/home/howtobuy.png";
import createnft1 from "../assets/home/createnft-1.png";
import createnft2 from "../assets/home/createnft-2.png";
import createnft3 from "../assets/home/createnft-3.png";
import team1 from "./../assets/home/team-1.png";
import team2 from "./../assets/home/team-2.png";
import team3 from "./../assets/home/team-3.png";
import team4 from "./../assets/home/team-4.png";
import nft1 from "./../assets/home/nft-1.png";
import nft2 from "./../assets/home/nft-2.png";
import nft3 from "./../assets/home/nft-3.png";
import nft4 from "./../assets/home/nft-4.png";
import blur from "./../assets/home/blur.png";
import roadmap from "./../assets/home/roadmap.svg";
import playicon from "./../assets/home/play-icon.svg";
import coinMatik from "./../assets/home/coin-matik.svg";
import coinUsdt from "./../assets/home/coin-usdt.svg";
import whitepaperPdf from "./../assets/home/whitepaper.pdf";
import Decimal from "decimal.js";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";

import shape2 from './../assets/images/common/shape_2.png'
import shape3 from './../assets/images/common/shape_3.png'
import shape4 from './../assets/images/common/shape_4.svg'

import Web3 from "web3";
import {
  staking_Address,
  USDT_Address,
  BURRO_Address,
  staking_abi,
  token_abi,
  presale_address,
  presale_abi,
} from "../components/config";
import { useNetwork, useSwitchNetwork } from "wagmi";

import { useAccount, useDisconnect } from "wagmi";
import {
  useContractReads,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";

const Home = (props) => {
  const [payAmount, set_payAmount] = useState(0);
  const [receiveAmount, set_receiveAmount] = useState(0);

  const [matik, setmatik] = useState("");
  const onClickMatik = () => {
    setmatik(true);
    setusdt(false);
    onPay(0);
  };
  const [usdt, setusdt] = useState("");
  const onClickUsdt = () => {
    setusdt(true);
    setmatik(false);
    onPay(0);
  };
  const openPDF = () => {
    window.open(whitepaperPdf, "_blank");
  };

  // Timer calculations start here
  const CHAIN_ID = "137";
  const { chain } = useNetwork();
  const { address, isConnecting, isConnected, isDisconnected } = useAccount();

  const targetDate = new Date("19 april 2024");
  targetDate.setDate( targetDate.getDate() ); //5 days set

  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());
  const [prog_percentage, set_prog_percentage] = useState(0);

  function getTimeRemaining() {
    const now = new Date();
    const timeDiff = Number(props.curr_presale.endTime) * 1000 - now;
    // const timeDiff = targetDate - now;

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return {
      days: days < 0 ? 0 : days,
      hours: hours < 0 ? 0 : hours,
      minutes: minutes < 0 ? 0 : minutes,
      seconds: seconds < 0 ? 0 : seconds,
    };
  }
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(getTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
  }, [props.curr_presale.endTime]);

  // Timer calculations end here
  useEffect(() => {
    setpercantage();
  }, [props.curr_presale.endTime]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useEffect(() => {
        const handleResize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return size;
}

  function setpercantage() {
    // set_prog_percentage((Number(100000000)) / (Number(props.curr_presale.supply)/10**18) * 100) ;
    set_prog_percentage(
      (Number(props.curr_presale.total_sold * 10) /
        10 ** 18 /
        (Number(props.curr_presale.supply) / 10 ** 18)) *
        100
    );
  }

  const {
    data: buy_Result,
    isLoading: isLoading_buy,
    isSuccess: buy_Success,
    write: buy_token1,
  } = useContractWrite({
    address: presale_address,
    abi: presale_abi,
    functionName: "buy_token",
    args: [
      receiveAmount ? Convert_To_Wei(receiveAmount) : 0,
      matik ? "0" : "1",
    ],
    value: matik ? Convert_To_Wei(payAmount ? Number(payAmount) : "0") : 0,
    onSuccess(data) {
      props.test();
      console.log("Success", data);
    },
  });

  const { config: usdtConfig } = usePrepareContractWrite({
    address: USDT_Address,
    abi: token_abi,
    functionName: "approve",
    args: [presale_address, payAmount ? Number(payAmount) * 10 ** 6 : "0"],
  });

  const {
    data: data_usdt,
    isLoading: isLoading_usdt,
    isSuccess: isSuccess_usdt,
    write: usdt_approval,
  } = useContractWrite(usdtConfig);

  const { switchNetwork: swap_switch_usdt } = useSwitchNetwork({
    chainId: CHAIN_ID,
    // throwForSwitchChainNotSupported: true,
    onSuccess() {
      usdt_approval?.();
    },
  });
  const { switchNetwork: swap_switch_matic } = useSwitchNetwork({
    chainId: CHAIN_ID,
    // throwForSwitchChainNotSupported: true,
    onSuccess() {
      buy_token1?.();
    },
  });

  const waitForTransaction_buy = useWaitForTransaction({
    hash: data_usdt?.hash,
    onSuccess(data) {
      buy_token1?.();
      console.log("Success", data);
    },
  });

  const [width] = useWindowSize();

const floatingDivStyle = {
    position: 'absolute',
    top: '0px',    // Adjust the value to position the div from the top of the section
    right: '20px',  // Adjust the value to position the div from the right of the section
    bottom: '20px', // Adjust the value to position the div from the bottom of the section
    width: '650px', // Optional if right is unset
    //height: '100px', // Optional if bottom is unset
    //backgroundColor: '#f0f0f0', // Background color of the div
    //border: '1px solid #ccc', // Border around the div
    padding: '10px', // Padding inside the div
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)', // Optional: Adds shadow to the div
    display: width > 768 ? 'block' : 'none' // Hide on mobile devices
};

const HeadingfontColor = {
  color: '#B4FF7D',
  
};
 
const SubHeadingfontColor = {
  color: '#FFFF00',  
};

const MainBackgroundColor = {   
  backgroundColor: '#B4FF7D' 
};

  function Convert_To_eth(val) {
    const web3 = new Web3(
      new Web3.providers.HttpProvider("https://polygon.meowrpc.com")
    );

    val = web3.utils.fromWei(val.toString(), "ether");
    return val;
  }

  function Convert_To_Wei(val) {
    const web3 = new Web3(
      new Web3.providers.HttpProvider("https://polygon.meowrpc.com")
    );

    val = web3.utils.toWei(val.toString(), "ether");
    return val;
  }

  function onPay(value) {
    if (value == "" || value == 0) {
      set_receiveAmount(0);
      // set_payAmount(0)

      return;
    }

    let price;
    if (!matik) {
      price = Number(props.curr_presale.price) / 10 ** 18;
    } else {
      price = Number(props.perTokenIn_Matic) / 10 ** 18;
    }

    let dec_price = new Decimal(price);
    value = new Decimal(value);
    let temp = value.div(dec_price);

    set_receiveAmount(Number(temp).toFixed(2));
  }

  function onRecieve(value) {
    if (value == "" || value == 0) {
      set_payAmount(0);

      return;
    }
    let price;
    if (!matik) {
      price = Number(props.curr_presale.price) / 10 ** 18;
    } else {
      price = Number(props.perTokenIn_Matic) / 10 ** 18;
    }

    let dec_price = new Decimal(price);
    value = new Decimal(value);
    let temp = dec_price.times(value);

    set_payAmount(Number(temp).toFixed(2));
  }

  function buy_token() {
    if (isDisconnected) {
      alert("Kindly connect your wallet");
      return;
    }
    if (payAmount == "" || payAmount == "0") {
      alert("Kidly write the amount");
      return;
    }

    if (matik) {
      if (Number(props.MATICBalance) < Number(Convert_To_Wei(payAmount))) {
        alert("You don't have enough Matic");
        return;
      }

      if (CHAIN_ID != chain.id) {
        swap_switch_matic?.();
      } else {
        buy_token1?.();
      }
    } else {
      console.log("object usdt");
      if (Number(props.USDTBalance) < Number(payAmount) * 10 ** 6) {
        alert("You don't have enough USDT");
        return;
      }

      if (CHAIN_ID != chain.id) {
        swap_switch_usdt?.();
      } else {
        usdt_approval?.();
      }
    }

    // token to token
  }
  return (
    <>
      <AnimatedSection>
        <div
          className="relative h-auto pb-10 bg-cover overflow-hidden"

          // style={{ backgroundImage: `url(${homeBanner})` }}
        >
          <div className="bg-overlay absolute inset-0">
          {/* <img className={`bg-video h-full w-full object-cover`} src="https://kangamoon.com/wp-content/uploads/2024/01/banner-bag-image.svg" alt=""></img> */}
          <img className={`bg-video h-full w-full object-cover`} src={bg2}  alt=""></img>
            {/* <video
              autoPlay
              className={`bg-video h-full w-full object-cover`}
              muted
              title="Video Preview"
              src="./bg.mp4"
              loop
            /> */}
          </div>
          <div className="absolute inset-0 bg-black opacity-70"></div>
          {/* <div className='relative z-50'><Navbar/></div> */}

          {/* hero section starts here */}
          <section className="m-auto pt-28" id="home">
          <div style={floatingDivStyle}>                 
                {/* <img src="https://kangamoon.com/wp-content/uploads/2024/01/rocket-op-banner.svg" alt="" className="rkt-pos"></img> */}
                {/* <img src="https://kangamoon.com/wp-content/uploads/2024/01/dicover-img.svg" alt=""></img> */}
                <img src={mafia1} alt=""></img>

          </div>

            <div className="flex flex-col justify-center items-center h-auto relative pt-5 mx-auto xs:w-full md:w-[30rem] lg:w-[50rem] 2xl:w-[65rem] max-w-[65rem] overflow-hidden">
              <h1 className="uppercase text-center text-4xl md:text-2xl my-5 font-zendots text-custom-green z-10">
                Burro Token
              </h1>
              <div className="text-white backdrop-filter backdrop-blur-md bg-white bg-opacity-[13%] border border-gray-400 rounded-xl p-8 z-10 min-w-[10rem] max-w-[27rem] md:w-[90%] h-auto">
                
                <p style={{ color:"yellow" ,paddingBottom:"10px" ,fontWeight:700}} >NEXT PRICE INCREASE IN</p>
                <div className="bg-custom-green rounded-lg text-black p-2" style={MainBackgroundColor}>
                  <ul className="flex justify-between text-center">
                    <li>
                      <span className="block font-semibold">Days</span>
                      <span className=" font-zendots sm:text-sm">
                        {timeRemaining.days
                          ? timeRemaining.days.toString().padStart(2, "0")
                          : 0}
                      </span>
                    </li>
                    <li>
                      <span className="block font-semibold">Hours</span>
                      <span className=" font-zendots sm:text-sm">
                        {timeRemaining.hours
                          ? timeRemaining.hours.toString().padStart(2, "0")
                          : 0}
                      </span>
                    </li>
                    <li>
                      <span className="block font-semibold">Minutes</span>
                      <span className=" font-zendots sm:text-sm">
                        {timeRemaining.minutes
                          ? timeRemaining.minutes.toString().padStart(2, "0")
                          : 0}
                      </span>
                    </li>
                    <li>
                      <span className="block font-semibold">Seconds</span>
                      <span className=" font-zendots sm:text-sm">
                        {timeRemaining.seconds
                          ? timeRemaining.seconds.toString().padStart(2, "0")
                          : 0}
                      </span>
                    </li>
                  </ul>
                </div>

                {/* <div className="text-center my-4 text-[14px]"> */}
                {/* <p>Stage {props.curr_stage?(Number(props.curr_stage)):""}</p> */}
                {/* <p>Your Stakeable $GBTC = 0</p> */}
                {/* </div> */}

                <p
                  className="text-center mt-5 mb-2 py-2 text-[21px]"
                  style={{ fontWeight:"bold", color: "#FFFF00" }}
                >
                 {/* <text style={{ fontWeight:"bold", color: "#7DF9FF" }}> Total Raised: </text> {Number(props.curr_presale.supply)/10**18}$  */}
                 <span style={{ fontWeight:"bold", color: "white" ,fontSize:"17px"}}> Total Raised: </span> {props.total_raised ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format((props.total_raised/10**6) * 10):0}

                </p>

 
                <div className="relative z-10 flex gap-20 sm:gap-0 mb-2">
                  <div >
                    <div>
                      <h1 style={{ color:"#FFFF00" , fontWeight:"bold"  }}>Stage</h1>
                    </div>
                    <div>
                      <p  > {Number(props.curr_stage) + 1} </p>
                    </div>

                  </div>
                  
                  <div className="sm:px-20 px-8"></div>

                    <div >
                      <div >
                      <h1 style={{ color:"#FFFF00" , fontWeight:"bold", textAlign:"right" }}>Remaining</h1>
                        </div>
                          {/* <p className="">{props.curr_presale.total_sold? Number(props.curr_presale.total_sold) / 10 ** 18: 0}{" "}/{" "}{props.curr_presale.supply? Number(props.curr_presale.supply) / 10 ** 18: 0} */}
                          <div style={{ textAlign:"right" }}>
                          <p  >{" "}{props.curr_presale.supply? (Number(props.curr_presale.supply) / 10 ** 18) - (Number(props.curr_presale.total_sold * 10) / 10 ** 18): 0}</p> 

                          </div>
                    </div>
                </div>

{/* 
                <p className="text-center mt-5 mb-2 text-[16px]">
                  {props.curr_presale.total_sold
                    ? Number(props.curr_presale.total_sold) / 10 ** 18
                    : 0}{" "}
                  /{" "}
                  {props.curr_presale.supply
                    ? Number(props.curr_presale.supply) / 10 ** 18
                    : 0}
                </p> */}


                <div className="w-full backdrop-filter backdrop-blur-md bg-opacity-[13%] bg-white rounded-md h-4">
                  <div  
                    className="bg-custom-green h-4 rounded-md"
                    style={{ width: `${Number(prog_percentage)}%` }}
                  ></div>
                </div>

                <div className="relative z-10 flex gap-40 mb-2">
                  <div className="text-center my-4 text-[13px] sm:text-[12px]">
                    <p style={{ fontWeight:"bold", color: "#FFFF00" }}>
                      1 BURRO ={" "}

                      {props.curr_presale.price
                        ? Number(props.curr_presale.price) / 10 ** 18
                        : ""}
                    </p>
                    {/* <p>Your Stakeable $GBTC = 0</p> */}
                  </div>
                  {Number(props.curr_stage) < 9 ? ( 
                    <div className="text-right my-4 text-[13px] sm:text-[12px]">
                      <p style={{ fontWeight:"bold", color: "#FFFF00" }}>
                        Next ={" "}
                        {props.NextStagePrice
                          ? Number(props.NextStagePrice) / 10 ** 18
                          : ""}
                      </p>
                    </div>
                  ) : null}
                </div>

                {/* <div className="text-center my-4 text-[16px]">
              <p>Your Purchased BURRO = {props.BURROBalance?(Number(props.BURROBalance)/10**18).toFixed(2):""}</p>
            </div> */}
                <div className="relative z-10 flex gap-4 mb-2">
                  <div
                    onClick={onClickMatik}
                    className={
                      matik
                        ? "border-2 border-white w-1/2 backdrop-filter backdrop-blur-md bg-opacity-[13%] bg-black rounded-md cursor-pointer"
                        : " border-none w-1/2 backdrop-filter backdrop-blur-md bg-opacity-[13%] bg-black rounded-md cursor-pointer"
                    }
                  >
                    <img
                      className="h-10 w-10 mr-2 inline-block"
                      src={coinMatik}
                    ></img>
                    <span className="absolute right-5 top-2">Matic</span>
                  </div>
                  <div
                    onClick={onClickUsdt}
                    className={
                      usdt
                        ? "border-2 border-white w-1/2 backdrop-filter backdrop-blur-md bg-opacity-[13%] bg-black rounded-md cursor-pointer"
                        : "border-none  w-1/2 backdrop-filter backdrop-blur-md bg-opacity-[13%] bg-black rounded-md cursor-pointer"
                    }
                  >
                    <img
                      className="h-10 w-10 mr-2 inline-block"
                      src={coinUsdt}
                    ></img>
                    <span className="absolute right-5 top-2">USDT</span>
                  </div>
                </div>
                <div className="flex justify-between items-center my-4">
                  <div className=" border-gray-400 h-1 w-1/10 mt-2"></div>
                  <span className="sm:text-[13px]">
                    {matik
                      ? "MATIC Balance = " +
                        (props.MATICBalance
                          ? Number(props.MATICBalance) / 10 ** 18
                          : 0
                        ).toFixed(2)
                      : "USDT Balance = " +
                        Number(
                          props.USDTBalance ? props.USDTBalance / 10 ** 6 : 0
                        ).toFixed(2)}
                  </span>
                  <div className="border-t border-gray-400 h-1 w-1/10 mt-2"></div>
                </div>
                <div className="flex gap-4">
                  <div className="w-full">
                    <label className="block mb-2 sm:text-[14px]">
                      {matik ? "MATIC You Pay" : "USDT You Pay"}
                    </label>
                    <input
                      className="w-full backdrop-filter backdrop-blur-md bg-opacity-[13%] bg-black rounded-md p-2"
                      type="Number"
                      disabled={props.perTokenIn_Matic > 0 ? false : true}
                      min={0}
                      value={payAmount}
                      onChange={(e) => {
                        set_payAmount(e.target.value);
                        onPay(e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <label className="block mb-2 sm:text-[12px]">
                      BURRO you recieve
                    </label>
                    <input
                      className="w-full backdrop-filter backdrop-blur-md bg-opacity-[13%] bg-black rounded-md p-2"
                      type="number"
                      disabled={props.perTokenIn_Matic > 0 ? false : true}
                      value={receiveAmount}
                      min={0}
                      onChange={(e) => {
                        set_receiveAmount(e.target.value);
                        onRecieve(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <p className="text-center my-4 sm:text-[14px]"></p>
                <button
                  className="bg-custom-green p-2 w-full rounded-sm text-black font-bold"
                  onClick={buy_token}
                >
                  Buy Now
                </button>
                <p className="text-center mt-4 sm:text-[14px]">
                  Listing On{" "}
                  <a href="#" style={{ color: "#FFFF00" }}>
                    P2B Exchange
                  </a>
                  ,{" "}
                  <a href="#" style={{ color: "#FFFF00" }}>
                    Bitmart
                  </a>
                  ,{" "}
                  <a href="#" style={{ color: "#FFFF00" }}>
                    Uniswap
                  </a>
                  ,{" "}

                  <a style={{ color: "#FFFF00" }} href="#">
                    {" "}
                    Sushiswap
                  </a>
                  ,{" "}

                  and{" "}
                  <a style={{ color: "#FFFF00" }} href="#">
                    Quickswap
                  </a>
                  .
                </p>
              </div>

              <p className="flex gap-2 mt-5 text-white font-semibold">
              Audit By <a href="https://coinsult.net/projects/burro">  <img className="w-20  h-100"  src={solidProof}></img></a>
              </p>
            </div>
          </section>


     


          {/* hero section ends here */}
          {/* {props.loader && <Loader />} */}
        </div>
      </AnimatedSection>

      {/* Powered by starts here */}
      <section className="bg-custom-black text-custom-green p-10 md:px-2">
        <div className="w-4/5 md:w-[90%] m-auto">
          <div className="flex gap-2 text-nowrap font-zendots">
            Powered By
            <div className="border-t border-white h-1 w-full mt-3"></div>
          </div>

          <div className="flex justify-between lg:overflow-x-scroll gap-10 my-5 mt-10">
            <div className="flex-shrink-0">
              <img className="h-8 w-25" src={openAi} />
            </div>
            <div className="flex-shrink-0">
              <img className="h-8 w-25" src={awshero}></img>
            </div>
            <div className="flex-shrink-0">
              <img className="h-8 w-25" src={metamaskhero}></img>
            </div>
            <div className="flex-shrink-0">
              <img className="h-8 w-25" src={uniswaphero}></img>
            </div>
            <div className="flex-shrink-0">
              <img className="h-8 w-25" src={trustwallethero}></img>
            </div>
          </div>
          <div className="mx-10 md:mx-0 mt-16 md:overflow-x-scroll max-w-full" style={SubHeadingfontColor}>
            <ul className="text-center md:text-left flex justify-between md:gap-3">
              <li className="flex-shrink-0 text-center">
                <span className="block font-zendots text-2xl">10M+</span>
                <span className="text-sm">People Reached</span>
              </li>
              <li className="flex-shrink-0 text-center">
                <span className="block font-zendots text-2xl">12M+</span>
                <span className="text-sm">Social Media Engaement</span>
              </li>
              <li className="flex-shrink-0 text-center">
                <span className="block font-zendots text-2xl">8K+</span>
                <span className="text-sm">Number of Media Mention</span>
              </li>
              <li className="flex-shrink-0 text-center">
                <span className="block font-zendots text-2xl">100K+</span>
                <span className="text-sm">People Visit A Website</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* powered by end here */}

      {/* about us startes here */}
      <section className="bg-white p-10 md:p-2">
        <div className="w-4/5 md:w-[90%] m-auto">
          <div
            id="aboutUs"
            className="flex justify-between gap-10 my-5 mt-10 lg:overflow-x-scroll"
          >
            <div className="flex-shrink-0">
              <img className="h-8 w-25" src={coingape} />
            </div>
            <div className="flex-shrink-0">
              <img className="h-8 w-25" src={ambcrypto}></img>
            </div>
            <div className="flex-shrink-0">
              <img className="h-8 w-25" src={cointelegraph}></img>
            </div>
            <div className="flex-shrink-0">
              <img className="h-8 w-25" src={coingape}></img>
            </div>
            <div className="flex-shrink-0">
              <img className="h-8 w-25" src={yahoo}></img>
            </div>
          </div>

          <AnimatedSection>
            <div className="grid grid-cols-2 md:grid-cols-1 my-16 items-center">
              <img className="md:order-2 md:mt-4" src={aboutUsImg}></img>
              <div className="p-10 md:p-4 md:order-1">
                <div className="flex gap-2 font-zendots md:justify-center">
                  <div className="md:hidden border-t-2 border-custom-black h-1 w-16 mt-3"></div>
                  About Us
                </div>
                <p className="text-2xl font-zendots my-3">
                  WELCOME TO THE WORLD OF BURRO
                </p>
                <p className=" text-sm">
                  We are an ultimate NFT token. Burro Token dominates all the
                  others as it has much more to offer than your regular utility
                  token as we will share 80% profit of revenue generated from
                  NFT sales and merchandise. We offer Buyback and Burn program
                  as well.
                </p>
                <p className="font-semibold text-sm my-3">
                  Let's join us for this fun Wildride of this Burro adventure
                  and enjoy this beautiful journey along with us.
                </p>
                <div className="flex gap-2 mt-7">
                  {/* <button className='bg-custom-black rounded px-7 p-2 text-white text-nowrap sm:px-5'>Get Started</button> */}
                  <button
                    className="border bg-custom-black rounded px-10 p-2 text-white font-semibold text-nowrap sm:px-5"
                    onClick={openPDF}
                  >
                    Whitepaper
                  </button>
                </div>
              </div>
            </div>
          </AnimatedSection>
        </div>
      </section>
      {/* about us ends here */}

      {/* BurroTekken Chart and Roadmap sections */}

      <section className="tf-section token" style={{paddingLeft:'10px', paddingRight:'10px'}}>
      <div className="bg-overlay absolute inset-0 bg-overlay-style-team">
              <img src={bgsky} alt="" />
            </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="tf-title aos-init aos-animate" data-aos="fade-up" data-aos-duration="800">
            <h1 className="uppercase text-center text-2xl md:text-xl mt-10 font-zendots text-custom-green z-10">
              Burro Token
            </h1>
              <div className="total_token">
                <h6>Total Supply: <span>10,000,000,000</span></h6>
                <h6>Listing Price: <span>$0.009</span></h6>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="content-progress-box">
              {[
                { title: "Team Token Allocation", percentage: "10.0%" },
                { title: "Chairity", percentage: "5.0%" },
                { title: "Burn Token Allocation", percentage: "10.0%" },
                { title: "Pre-Sale Allocation", percentage: "38.5%" },
                { title: "Staking DEX & Rewards", percentage: "36.5%" }
              ].map((item, index) => (
                <div key={index} className="progress-bar" data-percentage={item.percentage}>
                  <p className="progress-title-holder">
                    <span className="progress-title">{item.title}</span>
                    <span className="progress-number-wrapper">
                      <span className="progress-number-mark" style={{ left: item.percentage }}>
                        <span className="percent">{parseInt(item.percentage)}%</span>
                      </span>
                    </span>
                  </p>
                  <div className="progress-content-outter">
                    <div className="progress-content" style={{ width: item.percentage }}></div>
                  </div>
                </div>
              ))}
            </div>
          </div>

        

        </div>
      </div>
    </section>



      <section className="  text-white p-16 md:p-6"  >
     
        <div className="w-4/5 m-auto md:w-[90%]">
          {/* BurroTekken Chart section strats */}
          <h1 className="uppercase text-center text-4xl md:text-2xl my-5 font-zendots text-custom-green z-10">
                Road Map
              </h1>

          {/* BurroTekken Chart section ends */}

          {/* Roadmap starts here */}
          <AnimatedSection>
            <div className="pt-2">
               {/* <img src={roadmap}></img>  */}
              <div className="container">
  <div className="row">
    <div className="col-md-12">
      <div className="roadmap-wrapper-style2 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
        <div className="left">
          <div className="roadmap-box-style active">
            <div className="icon">
              
              <img src={down} alt="" />
            </div>
            <div className="content">
              <h6 className="date roadmapHeadingColor block font-zendots text-2xl">Project Phase 1</h6>
              <ul>
                <li>Market Research</li>
                <li>Project Website Release</li>
                <li>Project Concept</li>
                <li>Smart Contract Deployment</li>
                <li>Tokenomics</li>
                <li>Whitepaper v.1 Release</li>
                <li>Legal Procedures</li>

              </ul>
            </div>
          </div>
          
          <div className="roadmap-box-style ">
            <div className="icon">
              <img src={down} alt="" />
            </div>
            <div className="content">
            <h6 className="date roadmapHeadingColor block font-zendots text-2xl">Project Phase 3</h6>
              <ul>
              <li>PR & Influencer Marketing</li>
                <li>Core NFT Minting Development</li>
                <li>Cryto Trackers Listing</li>
                <li>Merchandise Selling Development</li>
                <li>Collaboration & Partnership</li>
              </ul>
            </div>
          </div>
          
          <div className="roadmap-box-style ">
            <div className="icon">
              <img src={down} alt="" />
            </div>
            <div className="content">
            <h6 className="date roadmapHeadingColor block font-zendots text-2xl">Project Phase 5</h6>
              <ul>
                <li>Burro Token swap launch on our site</li>
                <li>Clan System</li>
                <li>Begun Nft sales</li>
                <li>Social In-NFT Functions</li>
                <li>Begun Merchandise Sales</li>
                <li>Event Based on Community Feedback</li>
                <li>Revenue Sharing Updates</li>
                <li>Buyback Plans</li>
              </ul>
            </div>
          </div>

        </div>
        <div className="right">

        <div className="roadmap-box-style ">
            <div className="icon">
              <img src={down} alt="" />
            </div>
            <div className="content">
            <h6 className="date roadmapHeadingColor block font-zendots text-2xl">Project Phase 2</h6>
              <ul>
                <li>Smart Contract Audit</li>
                <li>Community Events Launch</li>
                <li>Token Presale Launch</li>
                <li>Community Building</li>
                <li>Token Staking Program Launch</li>
              </ul>
            </div>
          </div>
          <div className="roadmap-box-style ">
            <div className="icon">
              <img src={down} alt="" />
            </div>
            <div className="content">
            <h6 className="date roadmapHeadingColor block font-zendots text-2xl">Project Phase 4</h6>
              <ul>
                <li>Token Presale Completion</li>
                <li>CEX and DEx Listing</li>
                <li>Giveaway & Airdrops Launch</li>
              </ul>
            </div>
          </div>
          
          
        </div>
      </div>
    </div>
  </div>
</div>

              
              
            </div>
          </AnimatedSection>

          {/* Roadmap ends here */}
        </div>
      </section>
      {/* BurroTekken and Roadmap sections end here */}

      {/* how to buy section starts */}

    

      <AnimatedSection>
        <section
          id="howToBuy"
          className="bg-white p-14 md:p-4 border-2 border-red-700"
        >
          <div className="w-4/5 md:w-[90%] m-auto mt-20">
            <h1 className="uppercase text-center text-2xl font-zendots text-[#040404] z-10">
              How To Buy
            </h1>
            <div className="grid grid-cols-2 md:grid-cols-1 items-center">
              <div className="p-10 md:p-4">
                <div className="relative">
                  <div className="border-2 absolute border-custom-green border-dashed h-full left-5" style={{borderColor:'#86FF00'}}></div>

                  <div className="z-20 flex items-center order-1 bg-black shadow-xl w-10 h-10 rounded-full my-auto absolute left-0">
                    <h1 className="mx-auto font-semibold font-zendots text-lg text-custom-green">
                      01
                    </h1>
                  </div>
                  <div className="pl-10">
                    <p className="font-zendots mt-2 ml-2">Connect Wallet</p>
                    <p className="text-sm text-[#222222] p-2">
                      To purchase Burro Token you need to connect your wallet,
                      which involves approving the contract on your wallet
                    </p>
                  </div>

                  <div className="pt-5">
                    <div className="z-20 flex items-center order-1 bg-black shadow-xl w-10 h-10 rounded-full my-auto absolute left-0">
                      <h1 className="mx-auto font-semibold font-zendots text-lg text-custom-green">
                        02
                      </h1>
                    </div>
                    <div className="pl-10">
                      <p className="font-zendots mt-2 ml-2">
                        SELECT PAYMENT TYPE
                      </p>
                      <p className="text-sm text-[#222222] p-2">
                        Select from our available MATIC & USDT
                        for payment
                      </p>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="z-20 flex items-center order-1 bg-black shadow-xl w-10 h-10 rounded-full my-auto absolute left-0">
                      <h1 className="mx-auto font-semibold font-zendots text-lg text-custom-green">
                        03
                      </h1>
                    </div>
                    <div className="pl-10">
                      <p className="font-zendots mt-2 ml-2">Buy Burro Token</p>
                      <p className="text-sm text-[#222222] p-2">
                        Simply input your amount, select buy and approve the
                        payment on your wallet
                      </p>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="z-20 flex items-center order-1 bg-black shadow-xl w-10 h-10 rounded-full my-auto absolute bottom-0 left-0">
                      <h1 className="mx-auto font-semibold font-zendots text-lg text-custom-green">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </h1>
                    </div>
                    <div className="pl-10">
                      <a href="#home">
                        <button className="bg-black text-custom-green mt-1 ml-2 px-10 p-2 rounded">
                          Buy Now
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative">
                {/* <img className="md:mt-5" src={howtobuy}></img> */}

                <VideoPlayer videoUrl="./video.mp4" />

                {/* <img src={playicon} className='object-cover w-12 h-12 absolute left-0 right-0 m-auto top-0 bottom-0'></img> */}
                <p className="font-zendots underline my-2 text-center">
                  View Step by Step
                </p>
              </div>
            </div>
          </div>
        </section>
      </AnimatedSection>

      {/* how to buy section ends */}

      <section className="tf-section project_4">
     
  <div className="container">
  
    <div className="row">
    <h1 className="uppercase text-center text-2xl my-10 font-zendots text-custom-green z-10">
              Create NFT
            </h1>
      {/* <div className="col-lg-10 col-md-9">
        <div className="tf-title left aos-init aos-animate" data-aos="fade-up" data-aos-duration="800">
          <h2 className="title">IGO projects have been excellently successful</h2>
        </div>
      </div> */}
       
     

      <div className="col-md-12" style={{marginTop:'20px'}}>
        <div className="project-box-style3_wrapper">
          <div className="project-box-style3 aos-init aos-animate" data-aos="fade-in" data-aos-duration="800">
            <div className="header_project">
              <div className="image">
                <img className="mask" src={nft1} alt="" />
                <div className="shape">
                  <img src={shape2} alt="" />
                </div>
              </div>
              <h5 className="heading"><a href="/project-list">Braying Burros</a></h5>
            </div>
            <div className="content">
              <div className="td td1">
                <p>Total raise</p>
                <p>$3970</p>
              </div>
              <div className="td td2">
                <p>Price</p>
                <p>0.05 USDT</p>
              </div>
              <div className="td td3">
                <p>Chain</p>
                <p>BSV</p> {/* Assuming "BSV" as the chain name, replace with correct value */}
              </div>
              <div className="td td4">
                <p>Next claim in</p>
                
              </div>
              <div className="td td5">
              <ul className="social">
                <li><a href="https://twitter.com"><FaTwitter aria-hidden="true" /></a></li>
                <li><a href="https://facebook.com"><FaFacebookF aria-hidden="true" /></a></li>
                <li><a href="https://instagram.com"><FaInstagram aria-hidden="true" /></a></li>
                <li><a href="https://linkedin.com"><FaLinkedinIn aria-hidden="true" /></a></li>
              </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12" style={{marginTop:'20px'}}>
        <div className="project-box-style3_wrapper">
          <div className="project-box-style3 aos-init aos-animate" data-aos="fade-in" data-aos-duration="800">
            <div className="header_project">
              <div className="image">
                <img className="mask" src={nft2} alt="" />
                <div className="shape">
                  <img src={shape2} alt="" />
                </div>
              </div>
              <h5 className="heading"><a href="/project-list">Braying Burros</a></h5>
            </div>
            <div className="content">
              <div className="td td1">
                <p>Total raise</p>
                <p>$3970</p>
              </div>
              <div className="td td2">
                <p>Price</p>
                <p>0.05 USDT</p>
              </div>
              <div className="td td3">
                <p>Chain</p>
                <p>BSV</p> {/* Assuming "BSV" as the chain name, replace with correct value */}
              </div>
              <div className="td td4">
                <p>Next claim in</p>
                
              </div>
              <div className="td td5">
              <ul className="social">
                <li><a href="https://twitter.com"><FaTwitter aria-hidden="true" /></a></li>
                <li><a href="https://facebook.com"><FaFacebookF aria-hidden="true" /></a></li>
                <li><a href="https://instagram.com"><FaInstagram aria-hidden="true" /></a></li>
                <li><a href="https://linkedin.com"><FaLinkedinIn aria-hidden="true" /></a></li>
              </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="col-md-12" style={{marginTop:'20px'}}>
        <div className="project-box-style3_wrapper">
          <div className="project-box-style3 aos-init aos-animate" data-aos="fade-in" data-aos-duration="800">
            <div className="header_project">
              <div className="image">
                <img className="mask" src={nft3} alt="" />
                <div className="shape">
                  <img src={shape2} alt="" />
                </div>
              </div>
              <h5 className="heading"><a href="/project-list">Donkey's Tale</a></h5>
            </div>
            <div className="content">
              <div className="td td1">
                <p>Total raise</p>
                <p>$3970</p>
              </div>
              <div className="td td2">
                <p>Price</p>
                <p>0.05 USDT</p>
              </div>
              <div className="td td3">
                <p>Chain</p>
                <p>BSV</p> {/* Assuming "BSV" as the chain name, replace with correct value */}
              </div>
              <div className="td td4">
                <p>Next claim in</p>
                
              </div>
              <div className="td td5">
              <ul className="social">
                <li><a href="https://twitter.com"><FaTwitter aria-hidden="true" /></a></li>
                <li><a href="https://facebook.com"><FaFacebookF aria-hidden="true" /></a></li>
                <li><a href="https://instagram.com"><FaInstagram aria-hidden="true" /></a></li>
                <li><a href="https://linkedin.com"><FaLinkedinIn aria-hidden="true" /></a></li>
              </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12" style={{marginTop:'20px'}}>
        <div className="project-box-style3_wrapper">
          <div className="project-box-style3 aos-init aos-animate" data-aos="fade-in" data-aos-duration="800">
            <div className="header_project">
              <div className="image">
                <img className="mask" src={nft4} alt="" />
                <div className="shape">
                  <img src={shape2} alt="" />
                </div>
              </div>
              <h5 className="heading"><a href="/project-list">Enchanted Ass-ets</a></h5>
            </div>
            <div className="content">
              <div className="td td1">
                <p>Total raise</p>
                <p>$3970</p>
              </div>
              <div className="td td2">
                <p>Price</p>
                <p>0.05 USDT</p>
              </div>
              <div className="td td3">
                <p>Chain</p>
                <p>BSV</p> {/* Assuming "BSV" as the chain name, replace with correct value */}
              </div>
              <div className="td td4">
                <p>Next claim in</p>
                
              </div>
              <div className="td td5">
              <ul className="social">
                <li><a href="https://twitter.com"><FaTwitter aria-hidden="true" /></a></li>
                <li><a href="https://facebook.com"><FaFacebookF aria-hidden="true" /></a></li>
                <li><a href="https://instagram.com"><FaInstagram aria-hidden="true" /></a></li>
                <li><a href="https://linkedin.com"><FaLinkedinIn aria-hidden="true" /></a></li>
              </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* <div className="bg-overlay-style-nft"></div> */}
</section>


      {/* create nft starts */}
       
      {/* create nft ends  */}
      {/* create team starts */}
      <section>
      <div className="bg-overlay-style-team"></div>
            {/* <div id="team" className=" mt-20 border-2 border-transparent">
              <h1 className="uppercase text-center text-2xl my-10 font-zendots text-custom-green z-10 pt-16">
                Meet our team
              </h1>
              <div className="flex md:flex-wrap gap-5 justify-center relative">
                <div className="p-2 rounded-2xl px-2 border-2 polygon border-gray-600">
                  <div className="relative">
                    <img src={team1}></img>
                    <div className="h-1/4 w-full bg-[#7DF9FF] z-10 absolute bottom-0">
                      <ul className="text-center text-black">
                        <li className="text-xl font-semibold mt-2">Andy</li>
                        <li className="font-zendots">CEO</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="p-2 rounded-2xl px-2 border-2 polygon border-gray-600">
                  <div className="relative">
                    <img src={team2}></img>
                    <div className=" h-1/4 w-full bg-[#7DF9FF] z-10 absolute bottom-0">
                      <ul className="text-center text-black">
                        <li className="text-xl font-semibold mt-2">Ahmad</li>
                        <li className="font-zendots">Developer</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="p-2 rounded-2xl px-2 border-2 polygon border-gray-600">
                  <div className="relative">
                    <img src={team3}></img>
                    <div className="h-1/4 w-full bg-[#7DF9FF] z-10 absolute bottom-0">
                      <ul className="text-center text-black">
                        <li className="text-xl font-semibold mt-2">
                          Deborah B
                        </li>
                        <li className="font-zendots">Marketing Head</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>  */}

            {/* <div className="bg-overlay absolute inset-0 bg-overlay-style-team">
              <img src={bgsky} alt="" />
            </div> */}
  <div id="team" className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="tf-title mb40 aos-init aos-animate" data-aos="fade-up" data-aos-duration="800">
        <h1 className="uppercase text-center text-2xl my-10 font-zendots text-custom-green z-10 pt-16">
                Meet our team
              </h1>
          {/* <h2 className="title">Meet the team</h2> */}
        </div>
      </div>
      <div className="col-md-12">
        <div className="team-box-wrapper">
          <div className="team-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">

            <img className="shape" src={shape3} alt="" />
            <img className="shape_ecfect" src={shape4} alt="" />
            <div className="image">
              <img src={team1} alt="" />
            </div>
            <div className="content">
              <h5 className="name"><a href="/team-details">Andy</a></h5>
              <p className="position">CEO</p>
              <ul className="social">
                <li>
                  <a href="#">
                    <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.20381 22V11.9655H11.5706L12.0757 8.05372H8.20381V5.55662C8.20381 4.42442 8.51692 3.65284 10.1423 3.65284L12.212 3.65199V0.153153C11.8541 0.10664 10.6255 0 9.19548 0C6.20942 0 4.16511 1.82266 4.16511 5.1692V8.05372H0.788086V11.9655H4.16511V22H8.20381Z" fill="#798DA3"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M22.5 2.17863C21.6819 2.5375 20.8101 2.77537 19.9012 2.89087C20.8363 2.33262 21.5499 1.45537 21.8854 0.398C21.0136 0.91775 20.0511 1.28488 19.0254 1.48975C18.1976 0.608375 17.0179 0.0625 15.7309 0.0625C13.2339 0.0625 11.2236 2.08925 11.2236 4.57388C11.2236 4.93138 11.2539 5.27512 11.3281 5.60237C7.5785 5.4195 4.26063 3.62238 2.03175 0.88475C1.64262 1.55988 1.41438 2.33262 1.41438 3.1645C1.41438 4.7265 2.21875 6.11112 3.41775 6.91275C2.69313 6.899 1.98225 6.68862 1.38 6.35725C1.38 6.371 1.38 6.38888 1.38 6.40675C1.38 8.5985 2.94337 10.419 4.9935 10.8384C4.62637 10.9388 4.22625 10.9869 3.811 10.9869C3.52225 10.9869 3.23075 10.9704 2.95712 10.9099C3.5415 12.696 5.19975 14.0091 7.1715 14.0518C5.637 15.2521 3.68863 15.9754 1.57938 15.9754C1.2095 15.9754 0.85475 15.9589 0.5 15.9135C2.49787 17.2019 4.86562 17.9375 7.419 17.9375C15.7185 17.9375 20.256 11.0625 20.256 5.10325C20.256 4.90387 20.2491 4.71138 20.2395 4.52025C21.1346 3.885 21.8867 3.09162 22.5 2.17863Z" fill="#798DA3"></path>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="team-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
          
          <img className="shape" src={shape3} alt="" />
          <img className="shape_ecfect" src={shape4} alt="" />
          <div className="image">
            <img src={team2} alt="" />
          </div>
          <div className="content">
            <h5 className="name"><a href="/team-details">Eric Jones</a></h5>
            <p className="position">Developer</p>
            <ul className="social">
              <li>
                <a href="#">
                  <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.20381 22V11.9655H11.5706L12.0757 8.05372H8.20381V5.55662C8.20381 4.42442 8.51692 3.65284 10.1423 3.65284L12.212 3.65199V0.153153C11.8541 0.10664 10.6255 0 9.19548 0C6.20942 0 4.16511 1.82266 4.16511 5.1692V8.05372H0.788086V11.9655H4.16511V22H8.20381Z" fill="#798DA3"></path>
                  </svg>
                </a>
              </li>
              <li>
                <a href="#">
                  <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.5 2.17863C21.6819 2.5375 20.8101 2.77537 19.9012 2.89087C20.8363 2.33262 21.5499 1.45537 21.8854 0.398C21.0136 0.91775 20.0511 1.28488 19.0254 1.48975C18.1976 0.608375 17.0179 0.0625 15.7309 0.0625C13.2339 0.0625 11.2236 2.08925 11.2236 4.57388C11.2236 4.93138 11.2539 5.27512 11.3281 5.60237C7.5785 5.4195 4.26063 3.62238 2.03175 0.88475C1.64262 1.55988 1.41438 2.33262 1.41438 3.1645C1.41438 4.7265 2.21875 6.11112 3.41775 6.91275C2.69313 6.899 1.98225 6.68862 1.38 6.35725C1.38 6.371 1.38 6.38888 1.38 6.40675C1.38 8.5985 2.94337 10.419 4.9935 10.8384C4.62637 10.9388 4.22625 10.9869 3.811 10.9869C3.52225 10.9869 3.23075 10.9704 2.95712 10.9099C3.5415 12.696 5.19975 14.0091 7.1715 14.0518C5.637 15.2521 3.68863 15.9754 1.57938 15.9754C1.2095 15.9754 0.85475 15.9589 0.5 15.9135C2.49787 17.2019 4.86562 17.9375 7.419 17.9375C15.7185 17.9375 20.256 11.0625 20.256 5.10325C20.256 4.90387 20.2491 4.71138 20.2395 4.52025C21.1346 3.885 21.8867 3.09162 22.5 2.17863Z" fill="#798DA3"></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="team-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
          
          <img className="shape" src={shape3} alt="" />
          <img className="shape_ecfect" src={shape4} alt="" />
          <div className="image">
            <img src={team3} alt="" />
          </div>
          <div className="content">
            <h5 className="name"><a href="/team-details">Deborah B</a></h5>
            <p className="position">Marketing Head</p>
            <ul className="social">
              <li>
                <a href="#">
                  <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.20381 22V11.9655H11.5706L12.0757 8.05372H8.20381V5.55662C8.20381 4.42442 8.51692 3.65284 10.1423 3.65284L12.212 3.65199V0.153153C11.8541 0.10664 10.6255 0 9.19548 0C6.20942 0 4.16511 1.82266 4.16511 5.1692V8.05372H0.788086V11.9655H4.16511V22H8.20381Z" fill="#798DA3"></path>
                  </svg>
                </a>
              </li>
              <li>
                <a href="#">
                  <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.5 2.17863C21.6819 2.5375 20.8101 2.77537 19.9012 2.89087C20.8363 2.33262 21.5499 1.45537 21.8854 0.398C21.0136 0.91775 20.0511 1.28488 19.0254 1.48975C18.1976 0.608375 17.0179 0.0625 15.7309 0.0625C13.2339 0.0625 11.2236 2.08925 11.2236 4.57388C11.2236 4.93138 11.2539 5.27512 11.3281 5.60237C7.5785 5.4195 4.26063 3.62238 2.03175 0.88475C1.64262 1.55988 1.41438 2.33262 1.41438 3.1645C1.41438 4.7265 2.21875 6.11112 3.41775 6.91275C2.69313 6.899 1.98225 6.68862 1.38 6.35725C1.38 6.371 1.38 6.38888 1.38 6.40675C1.38 8.5985 2.94337 10.419 4.9935 10.8384C4.62637 10.9388 4.22625 10.9869 3.811 10.9869C3.52225 10.9869 3.23075 10.9704 2.95712 10.9099C3.5415 12.696 5.19975 14.0091 7.1715 14.0518C5.637 15.2521 3.68863 15.9754 1.57938 15.9754C1.2095 15.9754 0.85475 15.9589 0.5 15.9135C2.49787 17.2019 4.86562 17.9375 7.419 17.9375C15.7185 17.9375 20.256 11.0625 20.256 5.10325C20.256 4.90387 20.2491 4.71138 20.2395 4.52025C21.1346 3.885 21.8867 3.09162 22.5 2.17863Z" fill="#798DA3"></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
          
          <img className="shape" src={shape4} alt="" />
          <img className="shape_ecfect" src={shape4} alt="" />
          <div className="image">
            <img src={team4} alt="" />
          </div>
          <div className="content">
            <h5 className="name"><a href="/team-details">Darrell Steward</a></h5>
            <p className="position">Senior Designer</p>
            <ul className="social">
              <li>
                <a href="#">
                  <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.20381 22V11.9655H11.5706L12.0757 8.05372H8.20381V5.55662C8.20381 4.42442 8.51692 3.65284 10.1423 3.65284L12.212 3.65199V0.153153C11.8541 0.10664 10.6255 0 9.19548 0C6.20942 0 4.16511 1.82266 4.16511 5.1692V8.05372H0.788086V11.9655H4.16511V22H8.20381Z" fill="#798DA3"></path>
                  </svg>
                </a>
              </li>
              <li>
                <a href="#">
                  <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.5 2.17863C21.6819 2.5375 20.8101 2.77537 19.9012 2.89087C20.8363 2.33262 21.5499 1.45537 21.8854 0.398C21.0136 0.91775 20.0511 1.28488 19.0254 1.48975C18.1976 0.608375 17.0179 0.0625 15.7309 0.0625C13.2339 0.0625 11.2236 2.08925 11.2236 4.57388C11.2236 4.93138 11.2539 5.27512 11.3281 5.60237C7.5785 5.4195 4.26063 3.62238 2.03175 0.88475C1.64262 1.55988 1.41438 2.33262 1.41438 3.1645C1.41438 4.7265 2.21875 6.11112 3.41775 6.91275C2.69313 6.899 1.98225 6.68862 1.38 6.35725C1.38 6.371 1.38 6.38888 1.38 6.40675C1.38 8.5985 2.94337 10.419 4.9935 10.8384C4.62637 10.9388 4.22625 10.9869 3.811 10.9869C3.52225 10.9869 3.23075 10.9704 2.95712 10.9099C3.5415 12.696 5.19975 14.0091 7.1715 14.0518C5.637 15.2521 3.68863 15.9754 1.57938 15.9754C1.2095 15.9754 0.85475 15.9589 0.5 15.9135C2.49787 17.2019 4.86562 17.9375 7.419 17.9375C15.7185 17.9375 20.256 11.0625 20.256 5.10325C20.256 4.90387 20.2491 4.71138 20.2395 4.52025C21.1346 3.885 21.8867 3.09162 22.5 2.17863Z" fill="#798DA3"></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  </section>
      {/* create team ends */}


      {/* faq starts */}
      <AnimatedSection>
        
        
        <section className="bg-white p-10">
          <Faq />
        </section>


      </AnimatedSection>

      {/* faq ends here */}
    </>
  );
};

export default Home;
